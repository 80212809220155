<template>
    <div v-if="invoiceDetail !== null" class="mt-4">
      <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl relative w-full">
        <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
            <i class="fa-solid fa-arrow-left"></i>
          </div>
        <div class="flex justify-center items-center ">
          <h1 class="heading-2 text-center">Invoice Number:  #{{invoiceDetail.invoiceNumber}}</h1>
        </div>
      </div>
      <div class="py-2">
        <div class="card bg-white rounded-xl p-4 my-2" v-if="invoiceDetail.notes !== ''">
          <div class="text-gray3 font-bold">Invoice Note </div>
          <div class="text-text1 heading-5">{{invoiceDetail.notes}}</div>
        </div>
      </div>
      <div>
           <div class="lg:grid grid-cols-12 gap-4">
              <div class="col-span-4 2xl:col-span-4">
                <div class="card bg-white rounded-xl p-4 mb-4">
                      <div class="mb-4">
                        <span class="text-primary heading-2  font-bold cursor-pointer" @click="$router.push({name: 'CustomersDetail', params: {customerId: invoiceDetail.customerId}})">{{invoiceDetail.companyName}}</span>
                      </div>
                      <div class=" flex justify-between">
                        <div class=" mb-4">
                          <p class="text-gray4">Invoice Date</p>
                          <p class="font-bold text-text1">{{invoiceDetail.invoiceDate | formatForDatePicker}}</p>
                      </div>
                      <div class=" mb-4">
                          <p class="text-gray4 text-right">Due Date</p>
                          <p class="font-bold text-text1 text-right">{{invoiceDetail.dueDate | formatForDatePicker}}</p>
                      </div>
                      
                      </div>
                      <div class=" mb-4">
                          <p class="text-gray4">Status</p>
                          <p :style="`color: ${invoiceDetail.invoiceStatusColor}`" class="font-bold text-text2">{{invoiceDetail.invoiceStatusName}}</p>
                      </div>
                      <div class="mb-4">
                          <p class="text-gray4 ">Billing Address:</p>
                          <p class="text-text1 heading-7" v-if="invoiceDetail.billingLine1 !== '' ||  invoiceDetail.billingLine2 !== '' ||  invoiceDetail.billingCity !== '' || invoiceDetail.billingState !== '' || invoiceDetail.billingZip !== ''">
                              <span>{{invoiceDetail.billingLine1}}<span v-if="invoiceDetail.billingLine1 !== '' &&  invoiceDetail.billingLine2 !== ''">, </span></span>
                              <span>{{invoiceDetail.billingLine2}}<span v-if="invoiceDetail.billingLine2 !== '' &&  invoiceDetail.billingCity !== ''">, </span></span>
                              <span>{{invoiceDetail.billingCity}}<span v-if="invoiceDetail.billingCity !== '' &&  invoiceDetail.billingState !== ''">, </span></span>
                              <span>{{invoiceDetail.billingState}} <span v-if="invoiceDetail.billingState !== '' &&  invoiceDetail.billingZip !== ''"></span></span>
                              <span v-if="invoiceDetail.billingZip !== ''">-{{invoiceDetail.billingZip}} </span>
                           </p>
                           <p class="text-text1 heading-7" v-else>-</p>
                      </div>
                      <div>
                          <p class="text-gray4 ">Shipping Address:</p>
                          <p class="text-text1 heading-7" v-if="invoiceDetail.shippingLine1 !== '' ||  invoiceDetail.shippingLine2 !== '' || invoiceDetail.shippingCity !== '' || invoiceDetail.shippingState !== '' || invoiceDetail.shippingZip !== ''">
                              <span>{{invoiceDetail.shippingLine1}} <span v-if="invoiceDetail.shippingLine1 !== '' &&  invoiceDetail.shippingLine2 !== ''">, </span></span> 
                              <span>{{invoiceDetail.shippingLine2}}<span v-if="invoiceDetail.shippingLine2 !== '' &&  invoiceDetail.shippingCity !== ''">, </span></span>
                              <span>{{invoiceDetail.shippingCity}}<span v-if="invoiceDetail.shippingCity !== '' &&  invoiceDetail.shippingState !== ''">, </span></span>
                              <span>{{invoiceDetail.shippingState}} <span v-if="invoiceDetail.shippingState !== '' &&  invoiceDetail.shippingZip !== ''"></span></span>
                              <span v-if="invoiceDetail.shippingZip !== ''">-{{invoiceDetail.shippingZip}} </span>
                           </p>
                           <p class="text-text1 heading-7" v-else>-</p>
                      </div>
                </div>
                <div class="card bg-white rounded-xl p-4 mb-4">
                  <div class="text-gray4 flex justify-between mb-4">
                    <div>
                      <p>Sales Person</p>
                      <p v-if="invoiceDetail.salesPersonName !== ''" class="text-text2">{{invoiceDetail.salesPersonName}}</p>
                      <p v-else class="text-text2">-</p>
                    </div>
                    <div>
                      <p>Created By</p>
                      <p class="text-text1 text-right">{{invoiceDetail.createdByUserFirstName}} {{invoiceDetail.createdByUserLastName}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" col-span-8 2xl:col-span-6 mb-10">
                <div class="card bg-white rounded-xl p-4 mb-4">
                  <div  class="" v-if="invoiceDetail.selectedItemList.length > 0">
                    <p class=" text-gray4 heading-4 font-bold mb-4">Items list:</p>
                    <div class="px-4 cursor-pointer hover:bg-gray1 border-b py-2" v-for="(data, index) in invoiceDetail.selectedItemList" :key="index" @click="showItemDetail(data)">
                      <div class="text-text2 text-sm flex mb-2 font-bold justify-between">
                        <p class="pr-4" >{{data.itemName}}</p>
                        <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
                      </div>
                      <div class="text-text2 flex">
                        <p class="w-32 sm:w-72 heading-6">{{data.itemPrice | amountOnlyNumber}}</p>
                        <span class="w-72 heading-6">Qty:{{data.itemQty}} <span class="pl-4" v-if="data.isTaxable">Taxed</span></span>
                      </div>
                      <div class="text-text2 flex">
                        <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
                      </div>
                    </div>
                  </div>
                    <div class="flex justify-between py-1 px-4">
                      <p class="text-text2 font-bold w-full text-right">Items Total (A):</p>
                      <p class="text-text2 font-bold w-full text-right">{{invoiceDetail.subTotal.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    <div class="divider mt-2 mb-4"></div>
                   <div v-for="(taxes, index) in finalTaxList" :key="index" class="hover:bg-gray1 border-b py-2 heading-6">
                      <div @click="showBreakDownDetail(taxes.taxId)" class="px-4 cursor-pointer">
                        <div class="flex w-full justify-between ">
                          <div class="text-gray4 w-28 sm:w-32">{{taxes.taxName.substr(0, 20)}} <span v-if="taxes.taxName.length > 20">...</span>
                          <p class="text-gray4 flex sm:hidden sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                          </div>
                          <p class="text-gray4 hidden sm:flex w-20 sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                          <p class="text-gray4 sm:w-32 text-right">{{taxes.ammount.toFixed(2) | amountOnlyNumber}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between py-2 px-4">
                      <p class="text-text2 font-bold w-full text-right">Total Tax (B):</p>
                      <p class="text-text2 font-bold w-full text-right">{{taxesAmmount.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    <div class="divider my-2"></div>
                    <div class="flex justify-between pt-2 px-4">
                      <p class="text-text2 font-bold w-full text-right">Total  (A + B):</p>
                      <p class="text-text2 font-bold w-full text-right">{{invoiceDetail.grandTotal.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    <div v-if="invoiceDetail.totalPaymentRecord > 0" class=" px-4 flex justify-between pb-2 pt-1">
                      <p class="text-success w-full text-right">Payment Recorded:</p>
                      <p class="text-success font-bold w-full text-right">{{invoiceDetail.totalPaymentRecord.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    
                    <div class="divider my-2"></div>
                    <div v-if="invoiceDetail.grandTotal - invoiceDetail.totalPaymentRecord > 0" class="flex justify-between pb-2 pt-1 px-4">
                      <p class="text-error w-full font-bold text-right">Due:</p>
                      <p class="text-error w-full font-bold text-right">{{invoiceDetail.grandTotal - invoiceDetail.totalPaymentRecord | amountOnlyNumber}}</p>
                    </div>
                  </div>
                  <div class="card bg-white rounded-xl p-4 mb-4" v-if="invoicePaymentRecords.length > 0">
                    <p class=" text-gray4 heading-4 font-bold mb-4">Payment List:</p>
                    <div style="overflow-y: auto; white-space: nowrap;" class="">
                      <div v-if="invoicePaymentRecords.length > 0">
                          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                            <div class="table_col w-32  flex items-center">
                              <span class="cursor-pointer">{{titleObj[0].title}}</span>
                            </div>
                            <div class="table_col w-40  flex items-center">
                              <span class="cursor-pointer">{{titleObj[1].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                              <span class="cursor-pointer">{{titleObj[2].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                              <span class="cursor-pointer">{{titleObj[3].title}}</span>
                            </div>
                            <div class="table_col w-40  flex items-center">
                              <span class="cursor-pointer">{{titleObj[4].title}}</span>
                            </div>
                            <div class="table_col w-32  flex items-center">
                              <span class="cursor-pointer">{{titleObj[5].title}}</span>
                            </div>
                          </div>
                          <div v-for="(data, index) in invoicePaymentRecords" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="paymentDetail(data.paymentId)">
                            <div class="table_col w-32 ">
                              <div class="">
                                <p class="  text-text2 font-bold" v-if="data.companyName !== ''">{{data.companyName}}</p>
                                <p class="  text-text2 font-bold" v-else>-</p>
                              </div>
                            </div>
                            <div class="table_col w-40 ">
                              <div class="flex items-center ">
                                <span class="  text-gray4 heading-6" v-if="data.transactionId !== ''">{{data.transactionId}}</span>
                                <span class="  text-gray4 heading-6 ml-10" v-else>-</span>
                              </div>
                            </div>
                            <div class="table_col w-32">
                              <div class="flex items-center">
                                <span class="  text-gray4 heading-6" v-if="data.paymentMethodName !== ''">{{data.paymentMethodName}}</span>
                                <span class="  text-gray4 heading-6" v-else>-</span>
                              </div>
                            </div>
                            <div class="table_col w-32 ">
                              <div class="flex items-center">
                                <span class="  text-gray4 heading-6" v-if="data.paymentAmount !== ''">{{data.paymentAmount | amountOnlyNumber}}</span>
                                <span class="  text-gray4 heading-6" v-else>-</span>
                              </div>
                            </div>
                            <div class="table_col w-40 ">
                              <div class="flex items-center">
                                <span v-if="data.createdByUserFirstName !== ''"  class=" text-gray4 heading-6">{{data.createdByUserFirstName + ' ' + data.createdByUserLastName}}</span>
                                <span class="  text-gray4 heading-6" v-else>-</span>
                              </div>
                            </div>
                            <div class="table_col w-32 ">
                              <div class="flex items-center">
                                <span v-if="data.addedDate !== ''"  class=" text-gray4 heading-6">{{data.addedDate | formatForDatePicker}}</span>
                                <span class="  text-gray4 heading-6" v-else>-</span>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <ItemDetail v-if="itemdetailShow" :itemDetail="selectedItemDetails" />
    </div>
  </template>
  <script>
  import ADMINAPI from '@/View/Admin/api/Admin.js'
  import ItemDetail from './ItemDetail.vue'
  export default {
    name: "customer",
    components: {
      ItemDetail
    },
    data() {
      return {
          invoiceDetail: null,
          titleObj: [
            {title: 'Compnay Name', icon: '', sortName: ''},
            {title: 'Transaction Id', icon: '', sortName: ''},
            {title: 'Payment Method', icon: '', sortName: ''},
            {title: 'Amount', icon: '', sortName: ''},
            {title: 'Recorded By', icon: '', sortName: ''},
            {title: 'Date', icon: '', sortName: ''},
          ],
          finalTaxList: [],
          invoicePaymentRecords: [],
          taxesAmmount: 0,
          vissibleTaxId: 0,
          selectedItemDetails: null,
          itemdetailShow: false,
              removeConfirm: false,
          itemBreakDonwShow: false,
          showMenuList: false,
          isSendInvoiceEmail: false,
      };
    },
    created() {},
    mounted() {
      this.getInvoiceDetails()
      this.$root.$on('closeItemDetailHandler', (data) => {
          console.log(data);
          this.itemdetailShow = false
          this.selectedItemDetails = null
      })
      this.$root.$on('closeBreackDownHandler', (data) => {
          console.log(data);
          this.itemBreakDonwShow = false
          this.vissibleTaxId = 0
      })
      this.$root.$on('confirmBoxHandler', (response) => {
        document.body.style = 'overflow: visible;'
          if (response) {
            this.deletesInvoice()
          }
          this.removeConfirm = false
      })
      this.$root.$on('bookingConfirmationHandler', () => {
        document.body.style = 'overflow: visible;'
          this.isSendInvoiceEmail = false
          this.showMenuList = false
      })
    },
    methods: {
      closeList () {
        this.showMenuList = false
      },
       updateInvoice () {
        this.$router.push({name: 'createInvoice', query: {invoiceId: this.invoiceDetail.invoiceId}})
      },
       paymentDetail (id) {
        this.$router.push({name: 'PaymentDetail', params: {paymentId: id}})
      },
      showBreakDownDetail (taxId) {
          this.itemBreakDonwShow = true
          this.vissibleTaxId = taxId
      },
      showItemDetail (detail) {
          this.itemdetailShow = true
          this.selectedItemDetails = detail
      },
      showPayDetail (id) {
          this.invoicePaymentRecords[id].showInfo = !this.invoicePaymentRecords[id].showInfo
      },
      recordPayment () {
          this.$router.push({name: 'recordPayment', query:{customerId: this.invoiceDetail.customerId, invoiceId: this.invoiceDetail.invoiceId}})
      },
      getInvoiceDetails() {
        console.log("call", this.$route);
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });
        ADMINAPI.GetInvoiceDetail(
          parseInt(this.$route.params.invoiceId),
          (response) => {
            
            this.invoiceDetail = response.Data;
            if (response.Data.selectedItemList.length > 0) {
             this.calculateTaxes(response.Data.selectedItemList) 
            }
            this.getPaymentlist()
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      },
      getPaymentlist() {
          this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
          ADMINAPI.getPaymntList(
            0,
            1000,
            '',
            '',
            '',
            '',
            this.invoiceDetail.invoiceId,
            '',
            (response) => {
              let tempList = []
              // this.invoicePaymentRecords = response.Data.tableRow !== null ? response.Data.tableRow  : []
              if (response.Data.tableRow !== null) {
                 response.Data.tableRow.map((data)=> {
                      tempList.push({...data, showInfo: false})
                  }) 
              }
              this.invoicePaymentRecords = tempList
              console.log("response getInvoicesList", response);
              this.$store.dispatch("SetLoader", { status: false, message: "" });
            },
            () => {
              this.resetPaginationValues = false
              this.$store.dispatch("SetLoader", { status: false, message: "" });
            }
          );
      },
      calculateTaxes (itemList) {
          let list = itemList
          list.map((data) => {
              let itemAmount = data.itemAmount
              data.taxRateList.map((rate) => {
                  let itemTaxamount = itemAmount * rate.taxValue / 100
                  let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
                  if (fIndex >= 0) {
                      this.finalTaxList[fIndex].ammount += itemTaxamount
                  } else {
                      let obj = {
                          taxName: rate.taxName,
                          taxValue: rate.taxValue,
                          ammount: itemTaxamount,
                          taxId: rate.taxId
                      }
                      this.finalTaxList.push(obj)
                  }
              })
              
          })
          this.finalTaxList.map(data=> {
              this.taxesAmmount += data.ammount
          })
      }
    },
    beforeDestroy() {
      this.$root.$off("confirmBoxHandler");
    },
  };
  </script>
  <style scoped>
  .table_col {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .name_col {
      min-width: 200px;
      max-block-size: 200px;
  }
  .des_col {
      min-width: 250px;
      max-block-size: 250px;
  }
  .pric_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .qnty_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .amt_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  </style>